import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import dayjs from 'dayjs';
import { useToast } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import {
  type CashflowForecastEntry,
  CashflowForecastEntrySource,
} from 'qonto/react/models/cash-flow-forecast-entry';
import { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import { useUpdateCashFlowForecast } from 'qonto/react/hooks/use-update-forecast-entries';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context.tsx';
import { SidepanelDeletionScopePopup } from 'qonto/react/components/cash-flow/components/sidepanel/components/deletion-scope-popup.tsx';
import { CashflowForecastDeletionScope } from 'qonto/react/models/cash-flow-forecast-formula';
import { ForecastEntryDisclaimer } from 'qonto/react/components/cash-flow/components/sidepanel/forecast-entry/forecast-entry-disclaimer.tsx';
import { getEntryTimeline } from 'qonto/react/components/cash-flow/utils/get-entry-timeline.ts';
import type { LabelTableInterval } from '../../models/labels-cashflow-display';
import type { CategoriesTableRow } from '../../models/categories-table-display';
import { Divider } from './divider';
import { ForecastEntry } from './forecast-entry';
import styles from './forecast-entry-wrapper.strict-module.css';
import { ForecastEntryEditForm } from './forecast-entry/forecast-entry-edit-form';
import { ForecastEntryless } from './forecast-entry/forecast-entryless';
import { ForecastEntryLoadingState } from './forecast-entry/forecast-entry-loading-state.tsx';
import { ForecastSelector } from './forecast-selector/forecast-selector.tsx';

interface ForecastEntryWrapperProps {
  entry?: CashflowForecastEntry | null;
  frequency?: CashflowPeriodRate;
  interval?: LabelTableInterval;
  category?: CategoriesTableRow;
  isFlowSelected: boolean;
}

export function ForecastEntryWrapper({
  entry,
  frequency,
  category,
  interval,
  isFlowSelected,
}: ForecastEntryWrapperProps): React.ReactNode {
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const { closeSidepanel, refreshChart, isFirstTimeExperience } =
    cashFlowSidePanelManager.useCashFlowSidePanel();

  const abilities = useEmberService('abilities');
  const { featureBooleanCashFlowFirstTimeExperience } = useFlags();
  const queryClient = useQueryClient();
  const selectedIntervalStart = dayjs(interval?.start);

  const categoryHasChildren = Boolean(category?.subRows?.length);
  const isUncategorized = category?.type === 'uncategorized';
  const isMonthlyFrequency = frequency === CashflowPeriodRate.Monthly;
  const { isPreviousMonth } = getEntryTimeline(interval);

  const isReadOnly =
    !isMonthlyFrequency ||
    categoryHasChildren ||
    isPreviousMonth ||
    isFlowSelected ||
    isUncategorized;

  const { mutate: mutateCashFlowForecast, isPending } = useUpdateCashFlowForecast({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['cashflow-timeseries'] });
      refreshChart();
    },
    onError: () => {
      showToast({
        text: formatMessage({ id: 'cash-flow.edit-forecast.error.toast' }),
        type: 'error',
      });
    },
    onSettled: () => {
      closeSidepanel();
    },
  });

  const handleDeleteForecastEntry = (): void => {
    if (entry?.source === CashflowForecastEntrySource.Manual) {
      mutateCashFlowForecast(
        {
          forecast_entries: [
            {
              amount: null,
              category_id: category?.id || '',
              month: selectedIntervalStart.month() + 1,
              year: selectedIntervalStart.year(),
            },
          ],
        },
        {
          onSuccess: () => {
            showToast({
              text: formatMessage({ id: 'cashflow.side-panel.forecast.delete.toast.this-month' }),
              type: 'info',
            });
          },
        }
      );
    } else {
      setIsDeletePopupOpen(true);
    }
  };

  const handleFormulaEntryDelete = (scope: CashflowForecastDeletionScope): void => {
    setIsDeletePopupOpen(false);
    const toastMessage = {
      [CashflowForecastDeletionScope.ThisMonth]: formatMessage({
        id: 'cashflow.side-panel.forecast.delete.toast.this-month',
      }),
      [CashflowForecastDeletionScope.ThisAndFutureMonths]: formatMessage({
        id: 'cashflow.side-panel.forecast.delete.toast.future-months',
      }),
      [CashflowForecastDeletionScope.AllMonths]: formatMessage({
        id: 'cashflow.side-panel.forecast.delete.toast.all-months',
      }),
    };

    const removeFormulaEntries = {
      [CashflowForecastDeletionScope.ThisMonth]: false,
      [CashflowForecastDeletionScope.ThisAndFutureMonths]: 'future_months',
      [CashflowForecastDeletionScope.AllMonths]: 'all_months',
    };

    mutateCashFlowForecast(
      {
        forecast_entries: [
          {
            amount: null,
            category_id: category?.id || '',
            month: selectedIntervalStart.month() + 1,
            year: selectedIntervalStart.year(),
            ...(removeFormulaEntries[scope]
              ? { remove_formula_entries: removeFormulaEntries[scope] }
              : {}),
          },
        ],
      },
      {
        onSuccess: () => {
          showToast({
            text: toastMessage[scope],
            type: 'info',
          });
        },
      }
    );
  };

  const handleEditForecastEntry = (): void => {
    setIsEditing(true);
  };

  if (!frequency || !category || !interval) {
    return null;
  }

  const showBottomForecastSelector = Boolean(
    featureBooleanCashFlowFirstTimeExperience &&
      abilities.can('fully interact with forecast cash-flow') &&
      !isReadOnly
  );

  const showTopForecastSelector = Boolean(
    isFirstTimeExperience &&
      featureBooleanCashFlowFirstTimeExperience &&
      !showBottomForecastSelector &&
      !isReadOnly
  );

  if (!entry) {
    return (
      <ForecastEntryless
        category={category}
        className={styles.forecast}
        frequency={frequency}
        interval={interval}
        isEditing={isEditing}
        isFlowSelected={isFlowSelected}
        isReadOnly={isReadOnly}
        onCreateForecastEntry={handleEditForecastEntry}
        showBottomForecastSelector={showBottomForecastSelector}
        showTopForecastSelector={showTopForecastSelector}
      />
    );
  }

  if (isPending) {
    return <ForecastEntryLoadingState />;
  }

  return (
    <section className={styles.forecast}>
      {showTopForecastSelector ? (
        <>
          <ForecastSelector />
          <Divider />
        </>
      ) : null}
      {isEditing ? (
        <ForecastEntryEditForm
          category={category}
          entry={entry}
          frequency={frequency}
          interval={interval}
        />
      ) : (
        <ForecastEntry
          entry={entry}
          frequency={frequency}
          isFlowSelected={isFlowSelected}
          isReadOnly={isReadOnly}
          onDeleteForecastEntry={handleDeleteForecastEntry}
          onEditForecastEntry={handleEditForecastEntry}
        />
      )}
      <ForecastEntryDisclaimer
        category={category}
        frequency={frequency}
        isFlowSelected={isFlowSelected}
      />
      {showBottomForecastSelector ? (
        <>
          <Divider />
          <ForecastSelector />
        </>
      ) : null}
      {entry.source === CashflowForecastEntrySource.Formula && (
        <SidepanelDeletionScopePopup
          forecastEntry={entry}
          isOpen={isDeletePopupOpen}
          onPopupCancel={() => {
            setIsDeletePopupOpen(false);
          }}
          onPopupConfirm={handleFormulaEntryDelete}
        />
      )}
    </section>
  );
}
