import { useEffect, useRef, type ReactNode } from 'react';
import cx from 'clsx';
import styles from './gradient-overlay.strict-module.css';

interface OverlayGradientProps {
  numberOfColumns: number;
  children: ReactNode;
  className?: string;
}

export function OverlayGradient({
  children,
  numberOfColumns,
  className,
}: OverlayGradientProps): ReactNode {
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (componentRef.current) {
      // Each column has a delay of 0.1s
      const totalDelay = numberOfColumns * 0.1 + 1;

      componentRef.current.style.setProperty('--total-delay', `${totalDelay}s`);
    }
  }, [numberOfColumns]);

  return (
    <div
      className={cx(styles.baseOverlay, className, styles[`overlay-${numberOfColumns}-col`])}
      data-testid="chart-overlay"
      ref={componentRef}
    >
      <div className={styles.overlayGradient}>{children}</div>
    </div>
  );
}
