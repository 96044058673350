import React, { useState, useMemo } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { LOCALES_DEFINITION } from '@repo/shared-config/constants/locales';
import {
  AccountIbanDownload as BaseAccountIbanDownload,
  type IbanFormValues,
} from '@repo/domain-kit/account-management';

interface BankAccount {
  id: string;
  iban: string;
  organization: {
    legalCountry: string;
  };
}

interface AccountIbanDownloadProps {
  bankAccount: BankAccount;
}

export function AccountIbanDownload({ bankAccount }: AccountIbanDownloadProps): React.ReactNode {
  const segment = useEmberService('segment');
  const bankAccountService = useEmberService('bankAccount');
  const toastFlashMessages = useEmberService('toastFlashMessages');

  const organizationLocale = bankAccount.organization.legalCountry.toLowerCase();
  const [isDownloading, setIsDownloading] = useState(false);

  const allLocales = useMemo(() => {
    return Object.values(LOCALES_DEFINITION).map(locale => locale.code);
  }, []);

  const sortedLocales = useMemo((): string[] => {
    return [...allLocales].sort((a, b) => {
      if (a === organizationLocale) {
        return -1;
      }

      if (b === organizationLocale) {
        return 1;
      }

      if (a === 'en') {
        return -1;
      }

      if (b === 'en') {
        return 1;
      }

      return a < b ? -1 : 1;
    });
  }, [allLocales, organizationLocale]);

  const handleSubmit = async (values: IbanFormValues): Promise<void> => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);

    try {
      const { locale } = values;

      if (locale === organizationLocale) {
        segment.track('account_details_download_local');
      } else {
        segment.track(`account_details_download_${locale}`);
      }

      await bankAccountService.downloadIbanPdf(bankAccount.id, locale);
    } catch {
      toastFlashMessages.toastError('An error occurred while downloading the IBAN PDF');
    } finally {
      setIsDownloading(false);
    }
  };

  const handleCopyIban = (): void => {
    try {
      const formattedIban = bankAccount.iban.replace(/(?<group>.{4})/g, '$<group> ').trim();

      void navigator.clipboard.writeText(formattedIban);

      toastFlashMessages.toastInfo('IBAN copied to clipboard');

      segment.track('account_details_iban_copied');
    } catch {
      toastFlashMessages.toastError('bank_accounts.share.copy_error');
    }
  };

  return (
    <BaseAccountIbanDownload
      isLoading={isDownloading}
      locales={sortedLocales}
      onCopyIban={handleCopyIban}
      onSubmit={handleSubmit}
    />
  );
}
