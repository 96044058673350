import { type ReactElement } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Disclaimer, SelectorToggle } from '@repo/design-system-kit';
import { IconInfoRoundedFilled } from '@repo/monochrome-icons';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import { SPEND_LIMITS } from 'qonto/constants/spend-limit';
import styles from './expense-permissions.strict-module.css';
import { LimitInput } from './limit-input';

const TRACKER_PER_TRANSFER_AMOUNT_SELECTED = 'manager-invitation_per-transfer-amount-selected';
const TRACKER_MONTHLY_AMOUNT_SELECTED = 'manager-invitation_monthly-amount-selected';

const PREDEFINED_MONTHLY_TRANSFER_LIMITS = [
  {
    value: SPEND_LIMITS.UNLIMITED,
    tracker: TRACKER_MONTHLY_AMOUNT_SELECTED,
  },
  { value: SPEND_LIMITS.VALUE_500, tracker: TRACKER_MONTHLY_AMOUNT_SELECTED },
  { value: SPEND_LIMITS.VALUE_1000, tracker: TRACKER_MONTHLY_AMOUNT_SELECTED },
  { value: SPEND_LIMITS.VALUE_5000, tracker: TRACKER_MONTHLY_AMOUNT_SELECTED },
  { value: SPEND_LIMITS.VALUE_10000, tracker: TRACKER_MONTHLY_AMOUNT_SELECTED },
];

const PREDEFINED_PER_TRANSFER_LIMITS = [
  {
    value: SPEND_LIMITS.UNLIMITED,
    tracker: TRACKER_PER_TRANSFER_AMOUNT_SELECTED,
  },
  { value: SPEND_LIMITS.VALUE_50, tracker: TRACKER_PER_TRANSFER_AMOUNT_SELECTED },
  { value: SPEND_LIMITS.VALUE_100, tracker: TRACKER_PER_TRANSFER_AMOUNT_SELECTED },
  { value: SPEND_LIMITS.VALUE_500, tracker: TRACKER_PER_TRANSFER_AMOUNT_SELECTED },
  { value: SPEND_LIMITS.VALUE_2000, tracker: TRACKER_PER_TRANSFER_AMOUNT_SELECTED },
];

interface PermissionsBase {
  title: string;
  subtitle: string;
  checked: boolean;
  image: string;
}

interface ExpensePermissionsProps {
  fullName: string;
  cardsPermissions: PermissionsBase;
  transfersPermission: PermissionsBase;
  debitPermission: PermissionsBase;
  checksPermission: PermissionsBase;
  supplierInvoicesPermission: PermissionsBase;
  updatePermission: (name: string) => void;
  monthlyTransferLimitAmount?: string;
  monthlyTransferLimitErrorMessage?: string;
  onMonthlyTransferLimitAmountChange: (value: string | null) => void;
  perTransferLimitAmount?: string;
  perTransferLimitErrorMessage?: string;
  onPerTransferLimitAmountChange: (value: string | null) => void;
  hasRestrictedPermissions: boolean;
  showNoPermissionsDisclaimer: boolean;
  onConfirmClick: () => void;
}

export function ExpensePermissions({
  fullName,
  cardsPermissions,
  transfersPermission,
  updatePermission,
  monthlyTransferLimitAmount,
  monthlyTransferLimitErrorMessage,
  onMonthlyTransferLimitAmountChange,
  perTransferLimitAmount,
  perTransferLimitErrorMessage,
  onPerTransferLimitAmountChange,
  hasRestrictedPermissions,
  debitPermission,
  checksPermission,
  supplierInvoicesPermission,
  showNoPermissionsDisclaimer,
  onConfirmClick,
  ...props
}: ExpensePermissionsProps): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <div className={styles['expense-permissions']} data-test-member-expense-permissions {...props}>
      <h1 className="title-1 mb-16" data-test-member-expense-permissions-title>
        <FormattedMessage id="invitation-flow.expense-permissions.title" />
      </h1>

      <p className="body-1 mb-32" data-test-member-expense-permissions-description>
        <FormattedMessage id="invitation-flow.accounts.description" values={{ fullName }} />
      </p>

      <SelectorToggle
        checked={cardsPermissions.checked}
        className="mb-16"
        data-test-expense-permissions-cards
        data-test-expense-permissions-cards-checked={cardsPermissions.checked}
        icon={<StaticThemedAsset alt="" assetPath={cardsPermissions.image} />}
        onChange={() => {
          updatePermission('cards');
        }}
        subtitle={cardsPermissions.subtitle}
        title={cardsPermissions.title}
      />

      <SelectorToggle
        checked={transfersPermission.checked}
        className="mb-16"
        content={
          <section className={styles['spend-limits']}>
            <LimitInput
              amount={monthlyTransferLimitAmount}
              amountErrorMessage={monthlyTransferLimitErrorMessage}
              data-test-expense-permissions-monthly-limit="true"
              onAmountChange={onMonthlyTransferLimitAmountChange}
              preDefinedValues={PREDEFINED_MONTHLY_TRANSFER_LIMITS}
              title={formatMessage({
                id: 'invitation-flow.expense-permissions.transfers.limits.monthly',
              })}
            />

            <LimitInput
              amount={perTransferLimitAmount}
              amountErrorMessage={perTransferLimitErrorMessage}
              data-test-expense-permissions-per-transfer-limit="true"
              onAmountChange={onPerTransferLimitAmountChange}
              preDefinedValues={PREDEFINED_PER_TRANSFER_LIMITS}
              title={formatMessage({
                id: 'invitation-flow.expense-permissions.transfers.limits.per-transfer',
              })}
            />
          </section>
        }
        data-test-expense-permissions-transfers
        data-test-expense-permissions-transfers-checked={transfersPermission.checked}
        icon={<StaticThemedAsset alt="" assetPath={transfersPermission.image} />}
        onChange={() => {
          updatePermission('transfers');
        }}
        subtitle={transfersPermission.subtitle}
        title={transfersPermission.title}
      />

      {hasRestrictedPermissions ? (
        <>
          <SelectorToggle
            checked={debitPermission.checked}
            className="mb-16"
            data-test-expense-permissions-debits
            data-test-expense-permissions-debits-checked={debitPermission.checked}
            icon={<StaticThemedAsset alt="" assetPath={debitPermission.image} />}
            onChange={() => {
              updatePermission('debit');
            }}
            title={debitPermission.title}
          />

          <SelectorToggle
            checked={checksPermission.checked}
            className="mb-16"
            data-test-expense-permissions-checks
            data-test-expense-permissions-checks-checked={checksPermission.checked}
            icon={<StaticThemedAsset alt="" assetPath={checksPermission.image} />}
            onChange={() => {
              updatePermission('checks');
            }}
            title={checksPermission.title}
          />
        </>
      ) : null}

      <SelectorToggle
        checked={supplierInvoicesPermission.checked}
        className="mb-32"
        content={
          <section>
            <footer className={styles.hint}>
              <span className={styles['hint-icon']}>
                <IconInfoRoundedFilled />
              </span>

              <span className="body-2" data-test-expense-permissions-hint-body>
                <FormattedMessage id="invitation-flow.expense-permissions.supplier-invoices.disclaimer" />
              </span>
            </footer>
          </section>
        }
        data-test-expense-permissions-supplier-invoices
        data-test-expense-permissions-supplier-invoices-checked={supplierInvoicesPermission.checked}
        icon={<StaticThemedAsset alt="" assetPath={supplierInvoicesPermission.image} />}
        onChange={() => {
          updatePermission('supplierInvoices');
        }}
        title={supplierInvoicesPermission.title}
      />

      {showNoPermissionsDisclaimer ? (
        <Disclaimer.Block
          className="mb-32"
          data-test-member-expense-permissions-no-permissions-disclaimer
          level="info"
        >
          <FormattedMessage id="invitation-flow.expense-permissions.no-permission-disclaimer" />
        </Disclaimer.Block>
      ) : null}

      <button
        className="btn btn--primary"
        data-test-member-expense-permissions-submit
        onClick={onConfirmClick}
        type="button"
      >
        <FormattedMessage id="btn.continue" />
      </button>
    </div>
  );
}
