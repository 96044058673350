import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, useToast } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { queryClient } from 'qonto/react/react-bridge/custom-providers';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import type { CategoriesTableRow } from 'qonto/react/components/cash-flow/models/categories-table-display.ts';
import { ForecastAILoading } from '../forecast-ai-loading';
import styles from './flash-forecast.strict-module.css';

const FLASH_FORECAST_VIEWS = {
  LOADING: 'loading',
  SUCCESS: 'success',
} as const;

export type FlashForecastViewType =
  (typeof FLASH_FORECAST_VIEWS)[keyof typeof FLASH_FORECAST_VIEWS];

export function FlashForecast({
  selectedCategories,
}: {
  selectedCategories: CategoriesTableRow[];
}): React.ReactNode {
  const [view, setView] = useState<FlashForecastViewType>(FLASH_FORECAST_VIEWS.LOADING);
  const { closeSidepanel } = cashFlowSidePanelManager.useCashFlowSidePanel();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();

  const onLoadingCompleted = (): void => {
    showToast({
      text: formatMessage(
        { id: 'cash-flow.side-panel.forecast.autofill.success.toast' },
        {
          firstMonth: dayjs().format('MMMM YYYY'),
          lastMonth: dayjs().add(11, 'month').format('MMMM YYYY'),
        }
      ),
      type: 'success',
    });
    setView(FLASH_FORECAST_VIEWS.SUCCESS);
  };

  const handleCTAClick = (): void => {
    closeSidepanel();
  };

  const invalidateQueries = useCallback(async (): Promise<void> => {
    await queryClient.invalidateQueries({ queryKey: ['cashflow-timeseries'] });
    await queryClient.invalidateQueries({ queryKey: ['cashflow-timeseries-flash-forecasts'] });
  }, []);

  useEffect(() => {
    if (view === FLASH_FORECAST_VIEWS.SUCCESS) {
      void invalidateQueries();
    }
  }, [invalidateQueries, view]);

  switch (view) {
    case FLASH_FORECAST_VIEWS.SUCCESS:
      return (
        <section>
          <div className={styles['success-block']} data-testid="flash-forecast-success">
            <img
              alt=""
              className={styles['success-illustration']}
              data-testid="flash-forecast-success-illustration"
              src="/illustrations/cash-flow/forecast/flash-forecast-success.svg"
            />
            <h2 className={styles.title} data-testid="flash-forecast-success-title">
              <FormattedMessage
                id="cash-flow.first-time-experience.sidepanel.flash.success.title"
                values={{ categoryName: selectedCategories[0]?.name ?? '' }}
              />
            </h2>
            <p className="body-2 mb-32" data-testid="flash-forecast-success-subtitle">
              <FormattedMessage id="cash-flow.first-time-experience.sidepanel.flash.success.subtitle" />
            </p>
            <Button data-testid="start-planning-cta" onPress={handleCTAClick} variant="primary">
              <FormattedMessage id="cash-flow.first-time-experience.sidepanel.flash.success.cta" />
            </Button>
          </div>
        </section>
      );
    case FLASH_FORECAST_VIEWS.LOADING:
    default:
      return (
        <section>
          <ForecastAILoading onLoadingCompleted={onLoadingCompleted} />
        </section>
      );
  }
}
