/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import {
  type ReactNode,
  useRef,
  useState,
  useEffect,
  useCallback,
  forwardRef,
  memo,
  type RefObject,
  type PropsWithChildren,
} from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { Button, Tooltip } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks/use-ember-service';
import { IconPenOutlined } from '@repo/monochrome-icons';
import { useQueryClient } from '@tanstack/react-query';
import { ForecastStatusBadge } from '@repo/domain-kit/cashflow';
import { useButton } from 'react-aria';
import React from 'react';
import cx from 'clsx';
import { getAmountProperties, parseFormattedAmount } from 'qonto/utils/amount';
import type { Amount } from 'qonto/react/models/amount';
import type { CategoriesTableRow } from 'qonto/react/components/cash-flow/models/categories-table-display.ts';
import type { LabelTableInterval } from 'qonto/react/components/cash-flow/models/labels-cashflow-display.ts';
import { useUpdateCashFlowForecast } from 'qonto/react/hooks/use-update-forecast-entries';
import type { CashflowForecastEntryUpdatable } from 'qonto/react/api/models/cash-flow-forecast-entry';
import { type CashFlowSidePanelPayload } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import { CashflowSidePanelTabs } from 'qonto/react/models/cash-flow-period.ts';
import {
  CashflowForecastEntrySource,
  type CashflowForecastEntry,
} from 'qonto/react/models/cash-flow-forecast-entry';
import type { CashflowSide } from 'qonto/react/models/cash-flow-timeseries';
import { getEntryTimeline } from 'qonto/react/components/cash-flow/utils/get-entry-timeline.ts';
import { LightningOutlined } from 'qonto/react/assets/icons/lightning-outlined';
import { useFirstTimeExperience } from '../../hooks/use-first-time-experience';
import styles from './styles.strict-module.css';
import { ProjectedCell } from './projected-cell';
import { CellTooltipWrapper } from './balance-cell/cell-tooltip-wrapper';
import { DisabledCell } from './balance-cell/disabled-cell';

const SENSITIVE_KEYS = ['Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

const CustomTrigger = forwardRef<HTMLDivElement, { className?: string } & PropsWithChildren>(
  function BalanceCellTrigger({ children, className, ...props }, ref) {
    const { buttonProps } = useButton(props, ref as RefObject<HTMLDivElement>);
    return (
      <div className={className} {...buttonProps} ref={ref}>
        {children}
      </div>
    );
  }
);

interface FlashForecastButtonProps {
  onBlur: () => void;
  onFocus: () => void;
  onMouseLeave: () => void;
  onMouseOver: () => void;
  onPress: () => void;
}

const FlashForecastButton = forwardRef<HTMLButtonElement, FlashForecastButtonProps>(
  function FlashForecastButton({ onBlur, onFocus, onMouseLeave, onMouseOver, ...props }, ref) {
    const { buttonProps } = useButton(props, ref as RefObject<HTMLButtonElement>);
    return (
      <button
        className={cx('btn', 'btn--icon-only', styles['flash-forecast'])}
        data-testid="flash-forecast-btn"
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        {...buttonProps}
        ref={ref}
        type="button"
      >
        <LightningOutlined />
      </button>
    );
  }
);

interface BalanceCellProps {
  categoryId?: string | null;
  amount: Amount<string> | undefined;
  forecastAmount?: Amount<string> | undefined;
  projectedAmount?: Amount<string> | undefined;
  forecast?: CashflowForecastEntry | null;
  interval: LabelTableInterval | undefined;
  flowType: CashflowSide;
  categories: CategoriesTableRow[]; // a balance can be related to multiple categories (type, parent, subcategory)
  onForecastEntryUpdate?: () => void;
  onNavigateDown?: (enterEditMode: boolean) => void;
  onNavigateLeft?: (enterEditMode: boolean) => void;
  onNavigateRight?: (enterEditMode: boolean) => void;
  onNavigateUp?: (enterEditMode: boolean) => void;
  onViewTransactions?: (payload: CashFlowSidePanelPayload) => void;
  isSelectedCell?: boolean;
  isForecastEditingEnabled?: boolean;
  isFlowSelected?: boolean;
  isHeaderCell?: boolean;
  isSubcategory?: boolean;
  showProjectedForecast?: boolean;
  flashForecastPreviewAmount?: Amount<string>;
  closeSidepanel: () => void;
  setDisplayForecastPreviewForCategory?: (categoryId: string | undefined) => void;
  displayForecastPreviewForCategory?: string;
  setConfirmFlashForecastForCategory?: (categoryId?: string) => void;
}

// Create a memoized version to prevent unnecessary re-renders
export const BalanceCell = memo(
  function BalanceCell({
    isHeaderCell = false,
    amount,
    interval,
    flowType,
    categories,
    onForecastEntryUpdate,
    onNavigateDown,
    onNavigateLeft,
    onNavigateRight,
    onNavigateUp,
    onViewTransactions,
    forecast,
    forecastAmount,
    projectedAmount,
    isForecastEditingEnabled = false,
    isFlowSelected = false,
    isSelectedCell = false,
    showProjectedForecast = false,
    isSubcategory = false,
    closeSidepanel,
    displayForecastPreviewForCategory,
    flashForecastPreviewAmount,
    setDisplayForecastPreviewForCategory,
    categoryId,
    setConfirmFlashForecastForCategory,
  }: BalanceCellProps): ReactNode {
    const queryClient = useQueryClient();
    const { locale, formatMessage, formatNumber } = useIntl();
    const abilities = useEmberService('abilities');
    const canUseFlashForecasts = abilities.can('use flash forecasts cash-flow');
    const { isFirstTimeExperience } = useFirstTimeExperience();
    const segment = useEmberService('segment');

    const [cachedForecast, setCachedForecast] = useState<CashflowForecastEntry | null | undefined>(
      forecast
    );

    const amountValue = Number(amount?.value ?? 0);
    const amountCurrency = amount?.currency ?? 'EUR';

    const displayAmountSign =
      (flowType === 'inflows' && amountValue < 0) || (flowType === 'outflows' && amountValue > 0);

    const displayForecastAmount = cachedForecast?.amount || forecastAmount;

    const forecastCurrency = displayForecastAmount?.currency ?? 'EUR';
    const forecastValue = Math.abs(Number(displayForecastAmount?.value ?? 0));

    const { isFutureMonth, isCurrentMonth, isPreviousMonth } = getEntryTimeline(interval);

    const forecastAmountString = formatNumber(forecastValue, {
      currency: forecastCurrency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const flashForecastPreviewAmountString = flashForecastPreviewAmount
      ? formatNumber(parseFloat(flashForecastPreviewAmount.value), {
          currency: flashForecastPreviewAmount.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : null;

    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(forecastAmountString);
    const [isFlashing, setIsFlashing] = useState(false);

    const hasForecast = Boolean(forecast);
    const displayForecastStatusBadge = hasForecast && !isFutureMonth && !isEditing;
    const hideForecastEntry = isCurrentMonth;

    const inputRef = useRef<HTMLInputElement | null>(null);
    const cellRef = useRef<HTMLButtonElement | null>(null);
    const cellTooltipTriggerRef = useRef<HTMLDivElement | null>(null);
    const flashForecastPreviewRef = useRef<HTMLButtonElement | null>(null);

    const toastFlashMessages = useEmberService('toast-flash-messages');

    // @ts-expect-error -- TODO: remove this once we have a proper currency type
    const properties = getAmountProperties(forecastCurrency, locale);
    const formattedAmount = parseFormattedAmount(editValue.replace(/[^\d,.]/g, ''), properties);
    const editValueNumeric = isNaN(formattedAmount) ? 0 : formattedAmount;

    let selectedValue;

    if (isPreviousMonth) {
      selectedValue = CashflowSidePanelTabs.Actual;
    } else if (isCurrentMonth) {
      selectedValue = canUseFlashForecasts
        ? CashflowSidePanelTabs.Forecast
        : CashflowSidePanelTabs.Actual;
    } else {
      selectedValue = CashflowSidePanelTabs.Forecast;
    }

    const createTabPayload = useCallback(() => {
      return {
        tabValues: {
          actual: {
            value: amountValue.toFixed(2),
            currency: amountCurrency,
          },
          forecast: {
            value: editValueNumeric.toFixed(2),
            currency: forecastCurrency,
          },
        },
        isFutureMonth,
        selectedValue,
        isLoading: false,
      };
    }, [
      amountCurrency,
      amountValue,
      forecastCurrency,
      isFutureMonth,
      editValueNumeric,
      selectedValue,
    ]);

    const { mutate: mutateCashFlowForecastAndNavigateDown } = useUpdateCashFlowForecast({
      onSuccess: async () => {
        onNavigateDown?.(true);
        setIsFlashing(false); // Reset first to remove the class
        void document.body.offsetHeight; // Trigger a reflow
        setIsFlashing(true); // Apply the class again
        setTimeout(() => {
          setIsFlashing(false); // Remove the class after the animation
        }, 1000); // Match animation duration

        await queryClient.invalidateQueries({ queryKey: ['cashflow-timeseries'] });
        onForecastEntryUpdate?.();
      },
      onError: () => {
        setEditValue(forecastAmountString);
        toastFlashMessages.toastError(formatMessage({ id: 'cash-flow.edit-forecast.error.toast' }));
      },
    });

    const { mutate: mutateCashFlowForecast } = useUpdateCashFlowForecast({
      onSuccess: async () => {
        setIsFlashing(false); // Reset first to remove the class
        void document.body.offsetHeight; // Trigger a reflow
        setIsFlashing(true); // Apply the class again
        setTimeout(() => {
          setIsFlashing(false); // Remove the class after the animation
        }, 1000); // Match animation duration
        await queryClient.invalidateQueries({ queryKey: ['cashflow-timeseries'] });
        onForecastEntryUpdate?.();
      },
      onError: () => {
        setEditValue(forecastAmountString);
        toastFlashMessages.toastError(formatMessage({ id: 'cash-flow.edit-forecast.error.toast' }));
      },
    });

    // Make sure to reset the preview when the component unmounts
    useEffect(() => {
      return () => {
        setDisplayForecastPreviewForCategory?.(undefined);
      };
    });

    if (!amount || isNaN(amountValue)) {
      return <span data-testid="balance-cell-empty">-</span>;
    }

    const handleKeyDown = (e: React.KeyboardEvent): void => {
      if (!SENSITIVE_KEYS.includes(e.key)) return;
      e.preventDefault();

      switch (e.key) {
        case 'ArrowUp':
          onNavigateUp?.(false);
          break;
        case 'ArrowDown':
          onNavigateDown?.(false);
          break;
        case 'ArrowLeft':
          onNavigateLeft?.(false);
          break;
        case 'ArrowRight':
          onNavigateRight?.(false);
          break;
        case 'Enter':
          handleClick();
          break;
      }
    };

    const handleClick = (): void => {
      if (!categories.length) return;

      onViewTransactions?.({
        forecast: cachedForecast,
        selectedCategories: categories,
        selectedInterval: interval,
        isFlowSelected,
        tab: createTabPayload(),
      });
    };

    const handleOpenAutofillConfirmation = (): void => {
      setConfirmFlashForecastForCategory?.(categoryId ?? undefined);
    };

    const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      setEditValue(e.target.value);
    };

    const handleStartEditMode = (): void => {
      if (!isForecastEditingEnabled) return;

      if (!isSelectedCell) {
        closeSidepanel();
      }

      setIsEditing(true);
      segment.track('cash-flow_forecast_input');
      requestAnimationFrame(() => {
        const input = inputRef.current;
        if (!input) return;

        input.focus();
        input.setSelectionRange(0, input.value.length);
      });
    };

    const updateForecastEntry = (navigateDown: boolean): void => {
      setIsEditing(false);

      if (!interval || !forecastCurrency || !categories[0]?.id) return;
      segment.track('cash-flow_forecast_input-confirmed');
      const date = new Date(interval.start);
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();

      const editedForecastAmountStringWithCurrency = formatNumber(editValueNumeric, {
        currency: forecastCurrency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

      // Prevents resetting the forecast if value is unchanged
      if (editedForecastAmountStringWithCurrency === forecastAmountString) return;

      setEditValue(editedForecastAmountStringWithCurrency);
      setCachedForecast(staleForecast =>
        staleForecast
          ? {
              ...staleForecast,
              formula: undefined,
              source: CashflowForecastEntrySource.Manual,
              amount: {
                currency: forecastCurrency,
                value: editValueNumeric.toString(),
              },
            }
          : {
              formula: undefined,
              source: CashflowForecastEntrySource.Manual,
              amount: {
                currency: forecastCurrency,
                value: editValueNumeric.toString(),
              },
            }
      );

      const forecastEntries: CashflowForecastEntryUpdatable[] = [
        {
          amount: {
            valid: true,
            value: {
              currency: forecastCurrency,
              value: `${editValueNumeric}`,
            },
          },
          category_id: categories[0].id,
          month,
          year,
        },
      ];

      if (navigateDown) {
        mutateCashFlowForecastAndNavigateDown({
          forecast_entries: forecastEntries,
        });
      } else {
        mutateCashFlowForecast({ forecast_entries: forecastEntries });
      }
    };

    const handleEditKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
      switch (e.key) {
        case 'Enter': {
          updateForecastEntry(false);
          break;
        }
        case 'Escape':
          setIsEditing(false);
          setEditValue(forecastAmountString);
          cellRef.current?.focus();
          break;
      }

      if (
        !/[0-9\s,.]/.test(e.key) &&
        !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)
      ) {
        e.preventDefault();
      }
    };

    const handleFlashForecastStart = (): void => {
      setDisplayForecastPreviewForCategory?.(categoryId ?? undefined);
    };

    const handleFlashForecastEnd = (): void => {
      setDisplayForecastPreviewForCategory?.(undefined);
    };

    const forecastCellValue =
      displayForecastPreviewForCategory &&
      categoryId &&
      displayForecastPreviewForCategory === categoryId
        ? flashForecastPreviewAmountString
        : editValue;

    const renderForecastContainer = (): JSX.Element | null => {
      if (!(isFutureMonth || isCurrentMonth) || isNaN(forecastValue)) return null;

      return (
        <div className={`${styles.forecastContainer}`} data-test-forecast-container>
          {isEditing ? (
            <input
              className={styles.balanceInput}
              data-test-forecast-amount-edit-mode
              inputMode="decimal"
              onBlur={() => {
                updateForecastEntry(false);
              }}
              onChange={handleEditChange}
              onKeyDown={handleEditKeyDown}
              pattern="[0-9.,]*"
              ref={inputRef}
              value={editValue}
            />
          ) : (
            <span
              className={`${styles.forecastAmount} ${
                !isForecastEditingEnabled && styles.forecastAmountDisabled
              } ${styles.forecastAmountEditable}
            ${isFlashing ? styles.forecastAmountFlash : ''}`}
              data-edit-mode-button
              data-test-forecast-amount
              onClick={handleClick}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleClick();
                }
              }}
              role="button"
              tabIndex={-1}
            >
              {forecastCellValue}
            </span>
          )}
        </div>
      );
    };

    const showForecastTooltip = hasForecast && !isHeaderCell && isSubcategory && isCurrentMonth;
    const showFlashForecastTooltip = Boolean(
      displayForecastPreviewForCategory && displayForecastPreviewForCategory === categoryId
    );

    // Use the legacy balance-button-selected class for highlighting
    const clickBtnClass = `${styles['balance-button']} ${isEditing ? styles.isEditing : ''} ${
      isSelectedCell ? styles['balance-button-selected'] : ''
    }`;

    if (isFirstTimeExperience && isFutureMonth) {
      return (
        <CellTooltipWrapper
          currentMonthData={{
            amount,
            projectedAmount,
            forecastAmount,
            forecast,
            flowType,
          }}
          isFutureMonth={isFutureMonth}
        >
          <DisabledCell>
            <span
              className={cx(styles.forecastAmount, styles.forecastAmountDisabled)}
              data-test-forecast-amount
              tabIndex={-1}
            >
              {forecastCellValue}
            </span>
          </DisabledCell>
        </CellTooltipWrapper>
      );
    }

    return (
      <>
        <CellTooltipWrapper
          currentMonthData={{
            amount,
            projectedAmount,
            forecastAmount,
            forecast,
            flowType,
          }}
          isCurrentMonth={isCurrentMonth}
          showForecastTooltip={showForecastTooltip}
          showProjectedForecast={showProjectedForecast}
        >
          <CustomTrigger className={styles['balance-button-container']} ref={cellTooltipTriggerRef}>
            <button
              className={clickBtnClass}
              data-cell-id={`${categoryId || ''}-${interval?.start || ''}`}
              data-test-balance-cell-button
              id="cashflow-balance-cell"
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              ref={cellRef}
              type="button"
            />
            <div className={styles.balanceButtonContentWrapper} data-testid="balance-cell">
              <div
                className={`${styles.balanceButtonContent} ${displayForecastStatusBadge ? styles.dualContent : ''}`}
              >
                {!isFutureMonth && (
                  <button
                    className={`${styles.amountContainer} ${isEditing ? styles.fadeOut : ''}`}
                    onClick={handleClick}
                    tabIndex={-1}
                    type="button"
                  >
                    <FormattedNumber
                      currency={amountCurrency}
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                      signDisplay={displayAmountSign ? 'exceptZero' : 'never'}
                      value={amountValue}
                    />
                  </button>
                )}

                {hideForecastEntry ? null : renderForecastContainer()}
                {displayForecastStatusBadge ? (
                  <ForecastStatusBadge
                    actualPercentage={forecast?.actualPercentage}
                    flowType={flowType}
                    isCurrentMonth={isCurrentMonth}
                  />
                ) : null}
              </div>
              {isHeaderCell && showProjectedForecast ? (
                <div className={styles.projectedCellContainer}>
                  <ProjectedCell
                    flowType={flowType}
                    projectedAmount={projectedAmount}
                    projectedPercentage={forecast?.projectedPercentage}
                  />
                </div>
              ) : null}
            </div>
          </CustomTrigger>
        </CellTooltipWrapper>

        {canUseFlashForecasts &&
        isForecastEditingEnabled &&
        isCurrentMonth &&
        !isFirstTimeExperience &&
        flashForecastPreviewAmount ? (
          <Tooltip
            closeDelay={0}
            delay={300}
            isOpen={showFlashForecastTooltip}
            label={formatMessage({ id: 'cash-flow.forecast.set-auto.tooltip' })}
            placement="top"
            tooltipTestId="tooltip"
          >
            <FlashForecastButton
              onBlur={handleFlashForecastEnd}
              onFocus={handleFlashForecastStart}
              onMouseLeave={handleFlashForecastEnd}
              onMouseOver={handleFlashForecastStart}
              onPress={handleOpenAutofillConfirmation}
              ref={flashForecastPreviewRef}
            />
          </Tooltip>
        ) : null}
        {isForecastEditingEnabled &&
        !isEditing &&
        !isPreviousMonth &&
        !hideForecastEntry &&
        !isSelectedCell ? (
          <Tooltip
            closeDelay={0}
            delay={300}
            label={formatMessage({ id: 'cashflow.side-panel.forecast.summary.menu.edit' })}
            placement="top"
            tooltipTestId="tooltip"
          >
            <Button
              className={styles['inline-icon']}
              data-testid="forecast-edit-icon"
              iconOnly
              onPress={handleStartEditMode}
            >
              <IconPenOutlined />
            </Button>
          </Tooltip>
        ) : null}
      </>
    );
  },
  (prevProps, nextProps) => {
    // PERFORMANCE: Simplified comparison function - ignore selection state completely

    // Only re-render for forecast editing changes
    if (prevProps.isForecastEditingEnabled !== nextProps.isForecastEditingEnabled) {
      return false; // Re-render
    }

    // Skip re-renders when only the sidepanel visibility changes
    // Compare only the minimal props needed for rendering
    return (
      prevProps.amount?.value === nextProps.amount?.value &&
      prevProps.amount?.currency === nextProps.amount?.currency &&
      prevProps.forecastAmount?.value === nextProps.forecastAmount?.value &&
      prevProps.displayForecastPreviewForCategory === nextProps.displayForecastPreviewForCategory &&
      prevProps.flashForecastPreviewAmount?.value === nextProps.flashForecastPreviewAmount?.value
    );
  }
);
