import { type ReactNode } from 'react';
import type { Row, Table } from '@tanstack/react-table';
import { useEventCallback } from 'usehooks-ts';
import { Checkbox } from '@repo/design-system-kit';
import type { Transaction } from 'qonto/react/graphql';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';

interface BulkCheckboxHeaderProps {
  table: Table<Transaction>;
}
export function BulkCheckboxHeader({ table }: BulkCheckboxHeaderProps): ReactNode {
  const { selectAll } = bulkSelectionManager.useBulkSelection();
  const isIndeterminate = table.getIsSomeRowsSelected();
  const isChecked = table.getIsAllRowsSelected();
  const resetAll = (): void => {
    table.resetRowSelection();
  };
  const handleOnChange = useEventCallback(() => {
    if (!isChecked && isIndeterminate) {
      resetAll();
    } else {
      table.toggleAllRowsSelected(!isChecked);
    }
    selectAll();
  });

  return (
    <Checkbox
      data-testid={`bulk-checkbox-header${isChecked ? '-checked' : ''}`}
      isIndeterminate={isIndeterminate}
      isSelected={isChecked}
      onChange={handleOnChange}
    />
  );
}

interface BulkCheckboxCellProps {
  row: Row<Transaction>;
}
export function BulkCheckboxCell({ row }: BulkCheckboxCellProps): ReactNode {
  const isChecked = row.getIsSelected();
  const { selectItem } = bulkSelectionManager.useBulkSelection();
  const handleOnChange = useEventCallback(event => {
    const toggleSelectedHandler = row.getToggleSelectedHandler();
    selectItem(row.original.id);
    toggleSelectedHandler(event);
  });

  return (
    <Checkbox
      data-testid={`bulk-checkbox-cell${isChecked ? '-checked' : ''}`}
      isDisabled={!row.getCanSelect()}
      isIndeterminate={row.getIsSomeSelected()}
      isSelected={isChecked}
      onChange={handleOnChange}
    />
  );
}
