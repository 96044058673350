import { type ReactNode } from 'react';
import { Avatar, LottiePlayer } from '@repo/design-system-kit';
import { importAsset } from 'qonto/helpers/import-asset';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './animation.strict-module.css';

interface AnimationProps {
  lottiePath: string;
  iconPath: string;
}

export function Animation({ lottiePath, iconPath, ...props }: AnimationProps): ReactNode {
  return (
    <div className={styles.animation} data-test-setup-animation {...props}>
      <Avatar
        data-test-setup-animation-qonto
        size="56"
        src={importAsset(['/illustrations/qonto-hub/hub-application/qonto-round.svg'])}
      />

      <LottiePlayer
        autoplay
        className={styles.lottie}
        data-test-setup-animation-lottie=""
        loop={false}
        path={useThemedAssetPath(lottiePath)}
      />

      <Avatar data-test-setup-animation-icon size="56" src={iconPath} />
    </div>
  );
}
