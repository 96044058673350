import { useState, useCallback, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Button } from '@repo/design-system-kit';
import cx from 'clsx';
import { PopupDestructive } from 'qonto/react/components/popups/destructive';
import styles from './footer.strict-module.css';

interface Transfer {
  editable: boolean;
  cancelable: boolean;
  isInternationalOut?: boolean;
}

interface TransferSidebarFooterProps {
  transfer: Transfer;
  editTransfer: (transfer: Transfer) => void;
  cancelTransfer: (transfer: Transfer) => Promise<void>;
}

export function TransferSidebarFooter({
  transfer,
  editTransfer,
  cancelTransfer,
}: TransferSidebarFooterProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const abilities = useEmberService('abilities');
  const reactPopup = useEmberService('reactPopup');
  const [isCancelling, setIsCancelling] = useState(false);

  const isEditable = abilities.can('update transfer') && transfer.editable;
  const isCancelable = abilities.can('cancel transfer') && transfer.cancelable;

  const handleEdit = useCallback(() => {
    editTransfer(transfer);
  }, [editTransfer, transfer]);

  const handleCancel = useCallback(async () => {
    setIsCancelling(true);

    segment.track('transfer_list_open_side_panel_cancel', {
      transfer_type: transfer.isInternationalOut ? 'swift' : 'sepa',
    });

    try {
      const result = await reactPopup.open(PopupDestructive, {
        title: formatMessage({ id: 'transfers.modals.cancel-transfer.title' }),
        subtitle: formatMessage({ id: 'transfers.modals.cancel-transfer.body' }),
        confirmText: formatMessage({ id: 'transfers.modals.cancel-transfer.confirm-button' }),
        cancelText: formatMessage({ id: 'transfers.modals.cancel-transfer.cancel-button' }),
      });

      if (result === 'confirm') {
        await cancelTransfer(transfer);
      }
    } finally {
      setIsCancelling(false);
    }
  }, [transfer, segment, reactPopup, formatMessage, cancelTransfer]);

  return (
    <div
      className={cx(styles.actions, isEditable && isCancelable && styles['actions-background'])}
      data-test-transfer-sidebar-footer
    >
      {isEditable ? (
        <Button
          className={styles['actions-btn']}
          data-test-transfer-sidebar-edit
          onPress={handleEdit}
          size="small"
          variant="primary"
        >
          {isCancelable
            ? formatMessage({ id: 'btn.edit' })
            : formatMessage({ id: 'btn.edit-transfer' })}
        </Button>
      ) : null}

      {isCancelable ? (
        <Button
          className={styles['actions-btn']}
          data-test-transfer-sidebar-cancel
          isLoading={isCancelling}
          onPress={handleCancel}
          size="small"
          variant="danger"
        >
          {isEditable
            ? formatMessage({ id: 'btn.cancel' })
            : formatMessage({ id: 'btn.cancel-transfer' })}
        </Button>
      ) : null}
    </div>
  );
}
