import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import { useOpenFirstRowCurrentMonthSidepanel } from '../../hooks/use-open-sidepanel-first-row-current-month';
import { useFirstTimeExperience } from '../../hooks/use-first-time-experience';
import { OverlayGradient } from './gradient-overlay';
import { OverlayCta } from './overlay-cta';
import styles from './chart-overlay.strict-module.css';

interface ChartOverlayProps {
  numberOfColumns: number;
  offset: number;
}

function FirstTimeExperienceCta({ numberOfColumns, offset }: ChartOverlayProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useSegment();
  const openSidepanel = useOpenFirstRowCurrentMonthSidepanel(numberOfColumns, offset);

  return (
    <OverlayCta
      ctaAction={() => {
        segment.track('cash-flow_fte_set-your-forecast');
        openSidepanel();
      }}
      ctaText={formatMessage({
        id: 'cash-flow.first-time-experience.chart-overlay.set-forecast.cta',
      })}
      subtitle={formatMessage({
        id: 'cash-flow.first-time-experience.chart-overlay.set-forecast.subheading',
      })}
      title={formatMessage({
        id: 'cash-flow.first-time-experience.chart-overlay.set-forecast.heading',
      })}
    />
  );
}

function UpsellCta(): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useSegment();
  const upsellManager = useEmberService('upsell-manager');
  return (
    <OverlayCta
      ctaAction={() => {
        segment.track('cash-flow_fte_start-trial');
        void upsellManager.upgradeRecommendationWithTransition('cfm_forecast');
      }}
      ctaText={formatMessage({ id: 'cash-flow.first-time-experience.chart-overlay.try-free.cta' })}
      subtitle={formatMessage({
        id: 'cash-flow.first-time-experience.chart-overlay.try-free.subheading',
      })}
      title={formatMessage({
        id: 'cash-flow.first-time-experience.chart-overlay.try-free.heading',
      })}
    />
  );
}

export function ChartOverlay({ numberOfColumns, offset }: ChartOverlayProps): ReactNode {
  const { hasFirstTimeExperienceForecastBeenSet } = useFirstTimeExperience();
  const { isFirstTimeExperience, isVisible: isSidepanelOpen } =
    cashFlowSidePanelManager.useCashFlowSidePanel();

  if (isSidepanelOpen) {
    return null;
  }

  if (hasFirstTimeExperienceForecastBeenSet) {
    return (
      <OverlayGradient
        className={cx(isFirstTimeExperience ? styles.fadein : styles.visible)}
        numberOfColumns={numberOfColumns}
      >
        <UpsellCta />
      </OverlayGradient>
    );
  }

  return (
    <OverlayGradient numberOfColumns={numberOfColumns}>
      <FirstTimeExperienceCta numberOfColumns={numberOfColumns} offset={offset} />
    </OverlayGradient>
  );
}
