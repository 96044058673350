import type { ReactElement } from 'react';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ConfirmLayout } from 'qonto/react/pricing/components/confirm-layout';
import { ConfirmHeader } from 'qonto/react/pricing/components/confirm-header';
import { ConfirmBillingSummary } from 'qonto/react/pricing/components/confirm-billing-summary';
import type {
  SubscriptionChangeDataContext,
  TargetSubscription,
  PriceBreakdown,
} from 'qonto/react/components/flows/subscription-change/data-context.type';
import { ConfirmDisclaimers } from 'qonto/react/components/flows/subscription-change/confirm-trial/confirm-disclaimers/confirm-disclaimers.tsx';

interface ConfirmUpgradeProps {
  context: SubscriptionChangeDataContext;
  hasProductChangeNotAvailableError: boolean;
  header: ReactElement;
  isDisabled: boolean;
  isLoading: boolean;
  onConfirm: () => void;
  subtitle: string;
  targetSubscription?: TargetSubscription;
  title: string;
}

export function ConfirmUpgrade({
  context,
  hasProductChangeNotAvailableError,
  header,
  isLoading,
  isDisabled,
  onConfirm,
  subtitle,
  targetSubscription,
  title,
}: ConfirmUpgradeProps): ReactElement {
  const { formatNumber, formatMessage, formatDate } = useIntl();
  const { estimatedPrice } = context;

  const [products, setProducts] = useState<PriceBreakdown[]>([]);

  const price = formatNumber(Number(targetSubscription?.prorated_amount.value), {
    style: 'currency',
    currency: targetSubscription?.prorated_amount.currency,
  });

  useEffect(() => {
    const productsArr: PriceBreakdown[] = [];

    if (title && price) {
      productsArr.push({
        title,
        price,
        testId: 'data-test-price-plan-breakdown',
      });
    }

    setProducts(productsArr);
  }, [price, title]);

  return (
    <ConfirmLayout header={header} testId="data-test-free-confirm-trial-revamp">
      <>
        <ConfirmHeader pricePlan={{ price, subtitle, title }} />
        <ConfirmBillingSummary
          billing={formatMessage(
            {
              id: 'subscription.change.confirmation.summary.upsizing.subtitle',
            },
            {
              date: formatDate(estimatedPrice.next_billing_date, {
                format: 'long',
              }),
            }
          )}
          confirmButtonTestId="data-test-free-trial-cta-revamp"
          confirmText={formatMessage({ id: 'subscription.change.addons.cta.free-trial' })}
          isCtaDisabled={isDisabled}
          isLoading={isLoading}
          onConfirm={onConfirm}
          price={formatNumber(Number(estimatedPrice.vat_included_amount.value), {
            style: 'currency',
            currency: estimatedPrice.vat_included_amount.currency,
          })}
          products={products}
          testId="data-test-confirm-trial-total"
          total={formatMessage({ id: 'subscription.change.confirmation.charge' })}
          totalEstimate={estimatedPrice}
        >
          <ConfirmDisclaimers
            context={context}
            hasProductChangeNotAvailableError={hasProductChangeNotAvailableError}
          />
        </ConfirmBillingSummary>
      </>
    </ConfirmLayout>
  );
}
