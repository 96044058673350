import dayjs from 'dayjs';
import {
  CashBurnComputationType,
  type CashflowTimeseries,
} from 'qonto/react/models/cash-flow-timeseries';
import type {
  CashflowTimeframe,
  CashflowTimeseries as CashflowTimeseriesApiResponse,
  TimeframeInterval,
} from 'qonto/react/api/models/cash-flow-timeseries';
import { camelizeKeys } from 'qonto/react/api/utils/camelize-keys';
import {
  CashflowFrequencyToInterval,
  CashflowPeriodRate,
} from 'qonto/react/models/cash-flow-period';

const getForecastAmount = <T = number>(amount: T, maxGrowthRate = 0.9): T => {
  return typeof amount === 'number'
    ? ((amount * (1 + Math.random() * maxGrowthRate)) as T)
    : ((Number(amount) * (1 + Math.random() * maxGrowthRate)).toFixed(2) as T);
};

export const generateMockTimeseriesResponse = ({
  timeframesNumber = 12,
  frequency = CashflowPeriodRate.Monthly,
  forecastedMonths = 0,
  beginningDate = dayjs(),
  hasSubcategories = true,
}: {
  timeframesNumber?: number;
  frequency?: CashflowPeriodRate;
  forecastedMonths?: number;
  beginningDate?: dayjs.Dayjs;
  hasSubcategories?: boolean;
}): CashflowTimeseriesApiResponse => {
  const interval = CashflowFrequencyToInterval[frequency];
  let startingAmount = 1800.0;
  let inflowAmount = 1100.0;
  let outflowAmount = -900.0;
  let transactionsCount = 26;
  let vatCreditAmount = 37.25;
  let vatDebitAmount = 180.0;

  const updateAmounts = (): void => {
    startingAmount += 200.0;
    inflowAmount += 300.0;
    outflowAmount -= 100.0;
    transactionsCount += 2;
    vatCreditAmount += 10.0;
    vatDebitAmount += 20.0;
  };

  const timeframe: TimeframeInterval = {
    inclusive_start_date: beginningDate.startOf(interval).toISOString(),
    exclusive_end_date: beginningDate
      .add(forecastedMonths, 'months')
      .startOf(interval)
      .toISOString(),
    requested_at: dayjs().toISOString(),
    aggregation_interval: frequency,
  };

  const apiResponse: CashflowTimeseriesApiResponse = {
    timeframe,
    categories_data: {
      inflow: [
        {
          id: 'cat_uuid_1',
          name: 'Tax Income',
          name_key: 'inflows.taxes.corporate-credit',
          color_key: 'category_green',
          icon_key: 'icon_calculator',
          subcategories: hasSubcategories
            ? [
                {
                  id: 'sub_uuid_1',
                  name: 'Salaries',
                  name_key: 'inflows.taxes.salary-deductions',
                  color_key: 'category_green',
                },
              ]
            : [],
        },
      ],
      outflow: [
        {
          id: 'cat_uuid_2',
          name: 'Operational Expenses',
          name_key: 'outflows.operational',
          color_key: 'category_blue',
          icon_key: 'icon_box',
          subcategories: [
            {
              id: 'sub_uuid_2',
              name: 'Office Supplies',
              name_key: 'office_supplies',
              color_key: 'category_blue',
            },
          ],
        },
      ],
    },
    timeframes: [],
    current_balance_amount: { currency: 'EUR', value: '1000' },
    vat_data: {
      credit_amount: { currency: 'EUR', value: '300' },
      debit_amount: { currency: 'EUR', value: '100' },
      net_amount: { currency: 'EUR', value: '200' },
    },
    cash_burn_data: {
      amount: { currency: 'EUR', value: '400' },
      computation_type: CashBurnComputationType.Default,
    },
  };

  const addTimeframeData = ({
    startDate,
    endDate,
    isForecasted = false,
  }: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
    isForecasted?: boolean;
  }): void => {
    updateAmounts();
    apiResponse.timeframes.push(
      v3TimeframeDataTemplate({
        startDate,
        endDate,
        startingAmount,
        inflowAmount,
        outflowAmount,
        transactionsCount,
        vatCreditAmount,
        vatDebitAmount,
        isForecasted,
      })
    );
  };

  // Past & Present timeframes
  for (let i = timeframesNumber - 1; i >= 0; i--) {
    const startDate = dayjs(beginningDate).subtract(i, interval).startOf(interval);
    const endDate = startDate.add(1, interval);
    addTimeframeData({ startDate, endDate });
  }

  // Forecasted timeframes
  for (let i = 1; i <= forecastedMonths; i++) {
    const startDate = dayjs(beginningDate).add(i, interval).startOf(interval);
    const endDate = startDate.add(1, interval);
    addTimeframeData({ startDate, endDate, isForecasted: true });
  }

  return apiResponse;
};

export const generateV3MockTimeseries = ({
  timeframesNumber = 12,
  frequency = CashflowPeriodRate.Monthly,
  forecastedMonths = 0,
}: {
  timeframesNumber?: number;
  frequency?: CashflowPeriodRate;
  forecastedMonths?: number;
}): CashflowTimeseries => {
  const response = generateMockTimeseriesResponse({
    timeframesNumber,
    frequency,
    forecastedMonths,
  });
  return camelizeKeys(response) as CashflowTimeseries;
};

const v3TimeframeDataTemplate = ({
  startDate,
  endDate,
  startingAmount,
  inflowAmount,
  outflowAmount,
  transactionsCount,
  vatCreditAmount,
  vatDebitAmount,
  isForecasted = false,
}: {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  startingAmount: number;
  inflowAmount: number;
  outflowAmount: number;
  transactionsCount: number;
  vatCreditAmount: number;
  vatDebitAmount: number;
  isForecasted?: boolean;
}): CashflowTimeframe => ({
  inclusive_start_date: startDate.toISOString(),
  exclusive_end_date: endDate.toISOString(),
  cash_flow_data: {
    vat: {
      credit_amount: { value: vatCreditAmount.toFixed(2), currency: 'EUR' },
      debit_amount: { value: vatDebitAmount.toFixed(2), currency: 'EUR' },
    },
    transactions_count: transactionsCount,
    starting_amount: { value: startingAmount.toFixed(2), currency: 'EUR' },
    ending_amount: { value: (startingAmount + 200.0).toFixed(2), currency: 'EUR' },
    projected_starting_amount: {
      value: '1000.00',
      currency: 'EUR',
    },
    projected_ending_amount: {
      value: '1200.00',
      currency: 'EUR',
    },
    inflows: {
      amount_sum: { value: isForecasted ? '0' : inflowAmount.toFixed(2), currency: 'EUR' },
      forecast: {
        amount_sum: {
          value: getForecastAmount<string>(inflowAmount.toFixed(2)),
          currency: 'EUR',
        },
        actual_percentage: '10',
        projected_percentage: '15',
        gap_to_forecast: {
          value: '1100.00',
          currency: 'EUR',
        },
      },
      transactions_count: transactionsCount / 2,
      categories_data_points: [
        {
          category_id: 'cat_uuid_1',
          transactions_count: transactionsCount / 2,
          amount_sum: { value: isForecasted ? '0' : inflowAmount.toFixed(2), currency: 'EUR' },
          forecast: {
            amount: {
              value: getForecastAmount<string>(inflowAmount.toFixed(2)),
              currency: 'EUR',
            },
            source: 'formula',
            formula: {
              start: { year: 2025, month: 1 },
              end: { year: 2025, month: 12 },
              operation: 'percentage',
              frequency_in_months: 1,
              percentage_increment: 0.1,
              sum_increment: null,
            },
          },
          alerts: [],
          subcategories_data_points: [
            {
              subcategory_id: 'sub_uuid_1',
              transactions_count: transactionsCount / 2,
              amount_sum: { value: isForecasted ? '0' : inflowAmount.toFixed(2), currency: 'EUR' },
              forecast: {
                amount: {
                  value: getForecastAmount<string>(inflowAmount.toFixed(2)),
                  currency: 'EUR',
                },
                source: 'formula',
                formula: {
                  start: { year: 2025, month: 1 },
                  end: { year: 2025, month: 12 },
                  operation: 'percentage',
                  frequency_in_months: 1,
                  percentage_increment: 0.1,
                  sum_increment: null,
                },
              },
              alerts: [],
            },
          ],
        },
      ],
    },
    outflows: {
      amount_sum: { value: isForecasted ? '0' : outflowAmount.toFixed(2), currency: 'EUR' },
      forecast: {
        amount_sum: {
          value: getForecastAmount<string>(outflowAmount.toFixed(2)),
          currency: 'EUR',
        },
        actual_percentage: '10',
        projected_percentage: '15',
        gap_to_forecast: {
          value: '1100.00',
          currency: 'EUR',
        },
      },
      transactions_count: transactionsCount / 2,
      categories_data_points: [
        {
          category_id: 'cat_uuid_2',
          transactions_count: transactionsCount / 2,
          amount_sum: { value: isForecasted ? '0' : outflowAmount.toFixed(2), currency: 'EUR' },
          forecast: {
            amount: {
              value: getForecastAmount<string>(outflowAmount.toFixed(2)),
              currency: 'EUR',
            },
            source: 'forecast',
            formula: {
              start: { year: 2025, month: 1 },
              end: { year: 2025, month: 12 },
              operation: 'percentage',
              frequency_in_months: 1,
              percentage_increment: 0.1,
              sum_increment: null,
            },
            actual_percentage: '10',
            gap_to_forecast: {
              value: '1100.00',
              currency: 'EUR',
            },
          },
          subcategories_data_points: [
            {
              subcategory_id: 'sub_uuid_2',
              transactions_count: transactionsCount / 2,
              amount_sum: { value: isForecasted ? '0' : outflowAmount.toFixed(2), currency: 'EUR' },
              forecast: {
                amount: {
                  value: getForecastAmount<string>(outflowAmount.toFixed(2)),
                  currency: 'EUR',
                },
                source: 'forecast',
                formula: {
                  start: { year: 2025, month: 1 },
                  end: { year: 2025, month: 12 },
                  operation: 'percentage',
                  frequency_in_months: 1,
                  percentage_increment: 0.1,
                  sum_increment: null,
                },
              },
            },
          ],
        },
      ],
    },
  },
});
