import { useCallback, useEffect, useMemo, useState } from 'react';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import type { CashFlowSidePanelContextType } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import { useFirstTimeExperience } from './use-first-time-experience';

interface ShouldAnimateFirstTimeExperience {
  isFirstTimeExperienceAnimationCompleted: boolean;
  setIsFirstTimeExperienceAnimationCompleted: (status: boolean) => void;
  shouldAnimateForFirstTimeExperience: boolean;
}

export const useShouldAnimateFirstTimeExperience = (
  cashFlowSidePanel: CashFlowSidePanelContextType
): ShouldAnimateFirstTimeExperience => {
  const { isFirstTimeExperience, hasFirstTimeExperienceForecastBeenSet } = useFirstTimeExperience();

  const firstTimeExperienceStorageKey = useMemo(
    () => 'cashflow-categories-table-first-time-experience-animation-completed',
    []
  );

  const hasAnimationBeenCompleted = useMemo(() => {
    return safeLocalStorage.getItem(firstTimeExperienceStorageKey) === 'true';
  }, [firstTimeExperienceStorageKey]);

  const setAnimationStatusInLocalStorage = useCallback(
    (status: boolean) => {
      safeLocalStorage.setItem(firstTimeExperienceStorageKey, status.toString());
    },
    [firstTimeExperienceStorageKey]
  );

  const [isFirstTimeExperienceAnimationCompleted, setIsFirstTimeExperienceAnimationCompleted] =
    useState<boolean>(hasAnimationBeenCompleted);

  useEffect(() => {
    if (hasAnimationBeenCompleted && isFirstTimeExperienceAnimationCompleted) return;

    if (isFirstTimeExperienceAnimationCompleted && !hasAnimationBeenCompleted) {
      setAnimationStatusInLocalStorage(true);
    }
  }, [
    isFirstTimeExperience,
    hasFirstTimeExperienceForecastBeenSet,
    isFirstTimeExperienceAnimationCompleted,
    hasAnimationBeenCompleted,
    setAnimationStatusInLocalStorage,
  ]);

  const shouldAnimateForFirstTimeExperience = useMemo(() => {
    return (
      isFirstTimeExperience &&
      hasFirstTimeExperienceForecastBeenSet &&
      !isFirstTimeExperienceAnimationCompleted &&
      cashFlowSidePanel.previousMode !== 'default' &&
      !cashFlowSidePanel.isVisible
    );
  }, [
    isFirstTimeExperience,
    hasFirstTimeExperienceForecastBeenSet,
    isFirstTimeExperienceAnimationCompleted,
    cashFlowSidePanel,
  ]);

  return {
    shouldAnimateForFirstTimeExperience,
    setIsFirstTimeExperienceAnimationCompleted,
    isFirstTimeExperienceAnimationCompleted,
  };
};
