import { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { DialogTrigger } from 'react-aria-components';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { IconEyeOutlined } from '@repo/monochrome-icons';
import type { DisplayColumn } from 'qonto/constants/table-view';
import type { LabelList } from 'qonto/react/graphql';
import { ColumnManagerPopover } from '../popover';
import styles from './styles.strict-module.css';

interface ColumnManagerTriggerProps {
  className?: string;
  visibleColumns: DisplayColumn[];
  hiddenColumns: DisplayColumn[];
  updateColumn: (column: DisplayColumn) => void;
  labelLists: LabelList[];
}

export function ColumnManagerTrigger({
  className,
  visibleColumns,
  hiddenColumns,
  updateColumn,
  labelLists,
  ...props
}: ColumnManagerTriggerProps): ReactNode {
  const segment = useEmberService('segment');

  const onOpenChange = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track('tables_main-column-mgmt-cta_clicked');
    }
  };

  const trackUpdateColumn = (column: DisplayColumn): void => {
    segment.track('tables_main-column-mgmt-popover_clicked');
    updateColumn(column);
  };

  return (
    <DialogTrigger onOpenChange={onOpenChange}>
      <Button
        className={cx(styles.trigger, className)}
        data-testid="column-manager-trigger"
        {...props}
      >
        <div className={styles['trigger-content']}>
          <IconEyeOutlined className={styles.icon} height={12} width={12} />
          <FormattedMessage id="transactions.modular_table.filter_button.columns" />
        </div>
      </Button>
      <ColumnManagerPopover
        hiddenColumns={hiddenColumns}
        labelLists={labelLists}
        updateColumn={trackUpdateColumn}
        visibleColumns={visibleColumns}
      />
    </DialogTrigger>
  );
}
